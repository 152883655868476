import { Dayjs } from 'dayjs';
import classnames from 'classnames';
import { TFunction } from 'react-i18next';
import { styled } from '@mui/material/styles';
import { Box, LinearProgress, linearProgressClasses, Typography } from '@mui/material';

import { NO_VALUE_ZERO } from '../../utils/config';
import { DefaultSearchableCell } from '../BaseTable';
import { TableCellRenderer } from '../../interfaces/general';
import { ForecastFE, GasForecastFE, UNIT } from '../../interfaces/uiv2';
import { convertMeasureUnitToUnitId, getFormattedDate, toFixed } from '../../utils/helpers';

import style from './style.module.scss';
import tradingViewStyle from '../TradingView/TradingView.module.scss';
import SmallTooltip from '../Tooltips/SmallTooltip';

interface FunctionProps {
    timeDefaultValue: 'date' | 'hour';
    timeDefaultVerb: 'startOf' | 'endOf';
    defaultValue: Dayjs;
    compareValue: Dayjs;
    timeValue: Dayjs | null;
    reset: boolean;
    compareVerb?: 'isBefore' | 'isAfter';
    includesEndInterval?: boolean;
}
export const getValueFromTime = ({
    defaultValue,
    compareValue,
    timeValue,
    timeDefaultVerb,
    timeDefaultValue,
    reset,
    compareVerb = 'isBefore',
    includesEndInterval
}: FunctionProps): [Dayjs, boolean] => {
    let value = defaultValue;
    if (timeValue) {
        if (!timeValue.isValid()) {
            // time input is not valid so we dont want to send another request
            return [defaultValue, true];
        }
        const newValue = value.set('hour', timeValue.hour()).set('minute', timeValue.minute());
        // we don't want to display forecast that is before our current time,
        value = newValue[compareVerb](compareValue) ? value : newValue;
    } else {
        value = value[timeDefaultVerb](timeDefaultValue);
    }

    return [includesEndInterval ? value.add(1, 'second') : value, reset];
};

export const renderFooterCellWrapper = (value: any, title: string) => {
    return (
        <Box component="span" sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            <Typography variant={'small4'} sx={{ mb: 0.5 }}>
                {title}
            </Typography>
            <Typography variant={'small3'}>{value}</Typography>
        </Box>
    );
};

export const renderFooterContent = (forecast: ForecastFE[], t: TFunction) => {
    return {
        _id: 'mockedFooter',
        customFooter: true,
        index: t('customFooter.total'),
        date: t('customFooter.allIntervals'),
        time: t('customFooter.allIntervals'),
        P10: toFixed(forecast.reduce((prev, crt) => prev + (crt.p10_value ? +crt.p10_value : 0), 0)),
        P25: toFixed(forecast.reduce((prev, crt) => prev + (crt.p25_value ? +crt.p25_value : 0), 0)),
        P75: toFixed(forecast.reduce((prev, crt) => prev + (crt.p75_value ? +crt.p75_value : 0), 0)),
        P90: toFixed(forecast.reduce((prev, crt) => prev + (crt.p90_value ? +crt.p90_value : 0), 0))
    };
};

export const renderGasFooterContent = (forecast: ForecastFE[], t: TFunction) => {
    return {
        _id: 'mockedFooter',
        customFooter: true,
        index: t('customFooter.total'),
        date: t('customFooter.allIntervals'),
        time: t('customFooter.allIntervals'),
        value: toFixed(forecast.reduce((prev, crt) => prev + (crt.value ? +crt.value : 0), 0))
    };
};

export const renderDemandFooterContent = (forecast: ForecastFE[], t: TFunction) => {
    return {
        _id: 'mockedFooter',
        customFooter: true,
        index: t('customFooter.total'),
        date: t('customFooter.allIntervals'),
        time: t('customFooter.allIntervals'),
        value: toFixed(forecast.reduce((prev, crt) => prev + (crt.value ? +crt.value : 0), 0))
    };
};

export const getTableHeadCells = ({
    t,
    assetMeasureUnit
}: {
    t: TFunction;
    assetMeasureUnit: UNIT;
}): TableCellRenderer<ForecastFE>[] => {
    const unitId = convertMeasureUnitToUnitId(assetMeasureUnit);

    return [
        {
            id: 'index',
            maxWidth: 50,
            label: t('tableHead.no'),
            value: (row) => {
                return (
                    <Typography variant="small4" className={classnames(style.default)}>
                        {row.index < 10 ? `0${row.index}` : `${row.index}`}
                    </Typography>
                );
            },
            sort: false
        },
        {
            id: 'date',
            label: t('tableHead.date'),
            justifyContent: 'center',
            // TODO: this should be true by design, at lets keep it false until api is working
            sort: false,
            value: (row, searchValue) => {
                return (
                    <DefaultSearchableCell
                        value={row.localDate || row.date}
                        searchValue={searchValue}
                        className={style.default}
                    />
                );
            }
        },
        {
            id: 'time',
            sort_id: 'time',
            justifyContent: 'center',
            label: t('tableHead.time'),
            // TODO: this should be true by design, at lets keep it false until api is working
            sort: false,
            value: (row, searchValue) => {
                return (
                    <DefaultSearchableCell
                        value={row.localTime || row.time}
                        searchValue={searchValue}
                        className={style.default}
                    />
                );
            }
        },
        {
            id: 'p10_value',
            label: `${t('tableHead.P10')} (${unitId})`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.p10_value, `${t('tableFooter.total')} (${unitId})`)
                            : row.p10_value || NO_VALUE_ZERO}
                    </Typography>
                );
            }
        },
        {
            id: 'p25_value',
            label: `${t('tableHead.P25')} (${unitId})`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.p25_value, `${t('tableFooter.total')} (${unitId})`)
                            : row.p25_value || NO_VALUE_ZERO}
                    </Typography>
                );
            }
        },
        {
            id: 'value',
            label: `${t('tableHead.value')} (${unitId})`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.value, `${t('tableFooter.total')} (${unitId})`)
                            : row.value || NO_VALUE_ZERO}
                    </Typography>
                );
            }
        },
        {
            id: 'p75_value',
            label: `${t('tableHead.P75')} (${unitId})`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.p75_value, `${t('tableFooter.total')} (${unitId})`)
                            : row.p75_value || NO_VALUE_ZERO}
                    </Typography>
                );
            }
        },
        {
            id: 'p90_value',
            label: `${t('tableHead.P90')} (${unitId})`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.customFooter
                            ? renderFooterCellWrapper(row.p90_value, `${t('tableFooter.total')} (${unitId})`)
                            : row.p90_value || NO_VALUE_ZERO}
                    </Typography>
                );
            }
        }
    ];
};

export const getDemandTableHeadCells = ({
    t,
    assetMeasureUnit
}: {
    t: TFunction;
    assetMeasureUnit: UNIT;
}): TableCellRenderer<ForecastFE>[] => {
    const unitId = convertMeasureUnitToUnitId(assetMeasureUnit);
    return [
        {
            id: 'index',
            maxWidth: 50,
            label: t('tableHead.no'),
            value: (row) => {
                return (
                    <Typography variant="small4" className={classnames(style.default)}>
                        {row.index < 10 ? `0${row.index}` : `${row.index}`}
                    </Typography>
                );
            },
            sort: false
        },
        {
            id: 'date',
            label: t('tableHead.date'),
            justifyContent: 'center',
            // TODO: this should be true by design, at lets keep it false until api is working
            sort: false,
            value: (row, searchValue) => {
                return (
                    <DefaultSearchableCell
                        value={row.localDate || row.date}
                        searchValue={searchValue}
                        className={style.default}
                    />
                );
            }
        },
        {
            id: 'time',
            sort_id: 'time',
            justifyContent: 'center',
            label: t('tableHead.time'),
            // TODO: this should be true by design, at lets keep it false until api is working
            sort: false,
            value: (row, searchValue) => {
                return (
                    <DefaultSearchableCell
                        value={row.localTime || row.time}
                        searchValue={searchValue}
                        className={style.default}
                    />
                );
            }
        },
        {
            id: 'data',
            label: `${t('tableHead.value')} (${unitId})`,
            justifyContent: 'center',
            value: (row) => {
                return (
                    <Typography variant="small4" className={classnames(style.default)}>
                        {toFixed(row?.value || 0, 2)}
                    </Typography>
                );
            },
            sort: false
        }
    ];
};

const ForecastedProgress = styled(LinearProgress)(() => ({
    height: 10,
    width: 100,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#e4e4e4'
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: '#6fd8b2'
    }
}));
// const RegularizationProgress = styled(LinearProgress)(() => ({
//     height: 10,
//     width: 100,
//     [`&.${linearProgressClasses.colorPrimary}`]: {
//         backgroundColor: '#e4e4e4'
//     },
//     [`& .${linearProgressClasses.bar}`]: {
//         backgroundColor: '#e55151'
//     }
// }));

const MeasuredProgress = styled(LinearProgress)(() => ({
    height: 10,
    width: 100,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: '#e4e4e4'
    },
    [`& .${linearProgressClasses.bar}`]: {
        backgroundColor: '#6a9bff'
    }
}));

export const getGasTableHeadCells = ({
    t,
    assetMeasureUnit,
    maxValue = 100
}: {
    t: TFunction;
    assetMeasureUnit: UNIT;
    maxValue?: number;
}): TableCellRenderer<GasForecastFE>[] => {
    const unitId = convertMeasureUnitToUnitId(assetMeasureUnit);

    return [
        {
            id: 'index',
            maxWidth: 50,
            label: t('tableHead.no'),
            value: (row) => {
                return (
                    <Typography variant="small4" className={classnames(style.default)}>
                        {row.index < 10 ? `0${row.index}` : `${row.index}`}
                    </Typography>
                );
            },
            sort: false
        },
        {
            id: 'date',
            label: t('tableHead.date'),
            justifyContent: 'center',
            // TODO: this should be true by design, at lets keep it false until api is working
            sort: false,
            value: (row, searchValue) => {
                const from = getFormattedDate({ value: row.from_date });
                const to = getFormattedDate({ value: row.to_date });
                const value = from ? `${from} - ${to}` : to;
                return <DefaultSearchableCell value={value} searchValue={searchValue} className={style.default} />;
            }
        },
        {
            id: 'forecasted',
            label: `${t('tableHead.forecasted')} (${unitId})`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">
                        {row.forecasted === null ? '-' : toFixed(row.forecasted, 1)}
                    </Typography>
                );
            }
        },
        {
            id: 'measured',
            label: `${t('tableHead.measured')} (${unitId})`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                return (
                    <Typography variant="small4">{row.measured === null ? '-' : toFixed(row.measured, 1)}</Typography>
                );
            }
        },
        {
            id: 'balance',
            label: `${t('tableHead.balance')} (${unitId})`,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                if (row.measured === null || row.forecasted === null) {
                    return <Typography variant="small4">-</Typography>;
                }
                const balance = row.measured - row.forecasted;
                return <Typography variant="small4">{toFixed(balance, 1)}</Typography>;
            }
        },
        {
            id: 'chart',
            label: (
                <Box className={classnames(tradingViewStyle.chartHeader, style.chartHeader)}>
                    <Box className={tradingViewStyle.legendItem}>
                        <Box className={classnames(tradingViewStyle.circle, tradingViewStyle.actual)}></Box>
                        <Typography variant="small4">{t('tableHead.measured')}</Typography>
                    </Box>
                    <Box className={tradingViewStyle.legendItem}>
                        <Box className={classnames(tradingViewStyle.circle, tradingViewStyle.forecast)}></Box>
                        <Typography variant="small4">{t('tableHead.forecasted')}</Typography>
                    </Box>
                </Box>
            ) as any,
            justifyContent: 'center',
            sort: false,
            value: (row) => {
                const forecasted = row.forecasted ? toFixed(row.forecasted, 2) : 0;
                const measured = row.measured ? toFixed(row.measured, 2) : 0;
                const min = 0;
                const normalise = (value: number) => ((value - min) * 100) / (maxValue - min);
                return (
                    <Box>
                        <SmallTooltip placement="top" title={t('tableHead.measured')}>
                            <Box className={tradingViewStyle.legendItem}>
                                <Box className={classnames(tradingViewStyle.circle, tradingViewStyle.actual)}></Box>
                                <MeasuredProgress variant="determinate" value={normalise(measured)} />
                            </Box>
                        </SmallTooltip>
                        <hr className={style.hr} />
                        <SmallTooltip placement="top" title={t('tableHead.forecasted')}>
                            <Box className={tradingViewStyle.legendItem}>
                                <Box className={classnames(tradingViewStyle.circle, tradingViewStyle.forecast)}></Box>
                                <ForecastedProgress variant="determinate" value={normalise(forecasted)} />
                            </Box>
                        </SmallTooltip>
                    </Box>
                );
            }
        }
    ];
};
